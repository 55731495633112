import React from 'react'
import logo from '../Assets/logo.png'

export default function Navbar() {
  return (
    <nav>
        <div className="drifter_logo">

            <img src={logo} alt="Drifter"  height='40px' width='160px' />


        </div>
      
    </nav>
  )
}
