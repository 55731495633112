// import React, { useEffect, useState } from "react";
import Faq from "react-faq-component"
import line from '../Assets/line.png'
import Wave from "./Wave";

export default function FAQ() {
    const data = {
         
        rows: [
            {
                title: "What sets Drifter apart from other travel platforms?",
                content: `Drifter is a comprehensive travel platform where you can find everything you need for your journey without having to browse multiple websites. It offers a convenient one-stop solution for all your travel needs.`,
            },
            {
                title: "How does Drifter ensure the accuracy and reliability of its destination information?",
                content:
                    "Drifter sources its destination information from reliable and verified sources, ensuring accuracy and reliability. Our team thoroughly researches and updates content to reflect the latest changes, ensuring travelers have access to up-to-date information.",
            },
            {
                title: "Can I access Drifter's features offline while traveling?",
                content: ` Yes, Drifter offers offline access to certain features, such as saved itineraries and downloaded maps. This allows travelers to access essential information even when they're in areas with limited internet connectivity. `,
            },
            {
                title: " Is Drifter accessible on multiple devices, such as smartphones, tablets, and desktop computers?",
                content: <p>Yes, Drifter is accessible across various devices including smartphones, tablets, and desktop computers. Whether you're planning your trip on the go or at home, you can easily access Drifter's features and plan your travels seamlessly from any device.</p>,
            },
        ],
    }
    const styles = {
        bgColor: '#FFF5ED',
        titleTextColor: "black",
        rowTitleColor: "black",
        rowContentPaddingLeft:'20px'
    };
    
    const config = {
        animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
    };
  return (
    <div className="faq">
        <div className="faq_heading">
            <h1> <span>Frequently </span> asked questions</h1>
            <img src={line} alt="" height='30px' width='250px' />
            <p>Have another question? Email us at contactus@drifter.co.in</p>
            
        </div>

        <div className="faq_contaner">
        <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>

        <Wave className='faq_wave'/>
         
      
    </div>
  )
}
