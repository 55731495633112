 import {React,useState,useEffect} from 'react'
 import './Style/Style.css'
 import './Style/MobileView.css'
 import './Style/MidSize.css'
 import './Style/MidSize.css'
import Navbar from './Components/Navbar'
import Hero1 from './Components/Hero1'
import Hero2 from './Components/Hero2'
import Features from './Components/Features'
import Working from './Components/Working '
import FAQ from './Components/FAQ'
import Contact from './Components/Contact'
import Footer from './Components/Footer'
// import Marque from './Components/Marquee'
import ImageSlider from './Components/ImageSlider'
import Comming from './Components/CommingSoon'
import Compare from './Components/Compare'
import { animateScroll as scroll } from 'react-scroll';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 
 
 
 
 
 
 
 
 export default function App() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 10000); // 10 seconds delay

    return () => clearTimeout(timer);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // notify bottom
  const scrollToContact = () => {
    scroll.scrollToBottom();
  };
 
   return (
     <div>
       <Navbar/>
       <Hero1 scrollToContact={scrollToContact}/>
        <Hero2/>
        <Compare/>

        <Features/>
        
        <ImageSlider/>
        
        <Working/>
       

        <Comming />

        <FAQ/>
        <Contact/>
        <ToastContainer/>
        <Footer/>
          {/* Popup */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <button className="close-btn" onClick={handleClosePopup}>X</button>
            <h2>🌟 Welcome to Drifter! 🌟</h2>
            <p>Embark on a journey of discovery with us! Whether you're a seasoned explorer or a first-time traveler, Drifter is here to inspire and guide your adventures. Ready to create unforgettable memories?</p>
            <p>📝 We want to know you more! Please take a moment to fill out our survey form. Your insights help us enhance and customize our services to better meet your needs. Thank you for being a part of the Drifter community!"</p>
            <a href="https://forms.gle/aP6hwGkMnmLGY5WX6">Survey Form</a>
          </div>
        </div>
      )}
        
         
         

        
        
     </div>
   )
 }
 