import Marquee from "react-fast-marquee";

import React from 'react'

export default function Marque() {
  return (
    <div className="marque">
          <Marquee className="Marquee_list">
   <h2> * Travel like a Drifter </h2>
   <h2> * Travel like a Drifter </h2>
   <h2> * Travel like a Drifter </h2>
   <h2> * Travel like a Drifter </h2>
   <h2> * Travel like a Drifter </h2>
   <h2> * Travel like a Drifter </h2>
</Marquee>
    </div>
  
  )
}
