import React from 'react'
import line from '../Assets/line.png'
import hero from '../Assets/hero.png'
import Wave from './Wave'

export default function Hero2() {
    return (
        <div className='hero2'>
            <div className="hero2heading">
                <h1> <span>What</span> we do different</h1>
                <img src={line} alt="" height='30px' width='250px' />

            </div>

            <div className="hero2contaner">
                <div className="hero2right">
                    <p id='hero2rightid'> Welcome to Drifter - where travel is anything but ordinary. Tired of cookie-cutter packages? So are we. Here, we provide a different approach: a one-stop marketplace designed for those who prefer to chart their own course. Say goodbye to generic trips and hello to personalized adventures tailored to your desires. Let's embark on a journey where you're in control of every step.</p>

                </div>
                <div className="hero2left">
                    <img src={hero} alt="" height='400px' width='400px'/>

                </div>
            </div>

            <Wave/>

        </div>
    )
}
