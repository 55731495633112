import React from 'react'
import line from '../Assets/line.png'
import feature1 from  '../Assets/feature1.png'
import feature2 from  '../Assets/feature2.png'
import feature3 from  '../Assets/feature3.png'
import Wave from './Wave' 

export default function Features() {
  return (
    <div className='features'>
        <div className="featureshead">
            <h1>Features</h1>
            <img src={line} alt="" height='30px' width='250px' />

        </div>

        <div className="featurescontaner">
          <div className="feature">
            <div className="features_list">
            <h3>Be a Local</h3>
             <p> Experience seamless travel planning with Drifter. Say goodbye to endless scrolling through multiple websites. Explore dream destinations effortlessly with our all-in-one solution.</p>
             <p>Drifter offers comprehensive destination info, covering popular attractions to hidden gems. Explore historical landmarks, outdoor activities, and local cuisine—all in one place.</p>
             <p>Stay confident with Drifter's real-time updates. Get instant weather forecasts, local events, and travel advisories—all at your fingertips.</p>
            </div>
            <div className="featurepic">
               <img src={feature1} alt="" height='480px' width='500px' />
            </div>
          </div>
          <div className=" featureSecond ">

          <div className="featurepic">
          <img id='feature2' src={feature2} alt="" height='540px' width='500px' />


</div>
            <div className="features_list">
             <h3>Be Flexible</h3>
             <p> Drifter's AI analyzes your preferences, travel history, and interests to  suggest personalized destinations, activities, and accommodations. Say  goodbye to endless scrolling - plan your perfect adventure effortlessly  with intelligent recommendations from Drifter.</p>
              <p>Explore our marketplace for all your travel needs, from trek guides for adventurous hikes to cab services and more, all without the hassle of bargaining. With us, enjoy seamless booking and fair prices, ensuring a stress-free journey every step of the way.</p>
              <p>At Drifter, join our vibrant travel community to share your experiences  and seek advice from fellow adventurers. Connect with like-minded  individuals, share tips, and ask questions, creating a supportive  network to enhance your travel journey. Join us and let's explore the  world together!</p>
            </div>
            
          </div>
          <div className="feature  ">
            <div className="features_list">
             <h3 id='susta'>Be Sustainable</h3>
             <p>At Drifter, we're committed to making travel sustainable and accessible  while raising awareness about environmental impact. Through  eco-conscious initiatives and promoting responsible tourism, we strive  to minimize our carbon footprint and preserve the planet for future  generations. Join us in our mission to travel responsibly and protect  our environment.</p>
             <p>Introducing Drifter Homestays, where we bridge cultures by bringing the  local experience to you. Immerse yourself in authentic hospitality,  savor traditional cuisine, and engage with local customs, all while  enjoying the comforts of home. Discover the true essence of your  destination with Drifter Homestays.</p>
            </div>
            <div className="featurepic">
            <img id='feature3' src={feature3} alt="" height='500px' width='500px' />


            </div>
          </div>

        </div>
        <Wave style={{visiblity:"hidden"}}/>

 
      
    </div>
  
  )
}
