import Marquee from "react-fast-marquee";

import React from 'react'

export default function Comming() {
    return (
        <div className="marque">
            <Marquee className="Marquee_list">
                <h2> * coming soon
                </h2>
                <h2> * coming soon
                </h2>
                <h2> * coming soon
                </h2>
                <h2> * coming soon
                </h2>
                <h2> * coming soon
                </h2>
                <h2> * coming soon
                </h2>
                <h2> * coming soon
                </h2>
                <h2> * coming soon
                </h2>
            </Marquee>
        </div>

    )
}
