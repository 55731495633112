import React from 'react'
import line from '../Assets/line.png'
import Working1 from '../Assets/working1.png'
import Working2 from '../Assets/working2.png'
import Working3 from '../Assets/working3.png'
import Working4 from '../Assets/working4.png'

export default function Working () {
  return (
    <div className='Working'>
        <div className="working_head">
            <h1> <span>How </span>it Works</h1>
            <img src={line} alt=""  height='30px' width='250px' />

        </div>

        <div className="working_contaner">
            <div className="work">
                <div className="working_info">
                    <h3>Search for the destination where you want to go.</h3>
                    <p>Welcome to Drifter, your gateway to adventure! Explore trending hotspots and off-beat gems tailored to your wanderlust. Discover the latest travel trends or uncover hidden treasures. Start your journey today and let Drifter guide you to unforgettable experiences, whether you seek the familiar or crave the extraordinary.</p>
                </div>
                <div className="workingpic">
                    <img src={Working1} alt="" height='300px' width='400px' />

                </div>
            </div>
            {/*  */}
            <div className="work work1">
            <div className="workingpic">
            <img src={Working2} alt="" height='300px' width='400px' />
                    
                    </div>
                <div className="working_info">
                    <h3>Find all destination information consolidated in one place.</h3>
                     <p>Simplify your trip planning with our all-inclusive guide. From essential information to insider tips, we provide everything you need for a perfect journey. Feel like a local as you explore with confidence, armed with our comprehensive resources to create unforgettable travel experiences.</p>
                </div>
               
            </div>
            {/*  */}
            <div className="work">
                <div className="working_info">
                     <h3>Secure genuine local experiences at affordable rates, free from scams.</h3>
                     <p>Explore the finest local experiences on Drifter. Say goodbye to bargaining in markets as we offer affordable, scam-free prices. Enjoy authentic encounters hassle-free, knowing you're getting the best value. Let Drifter guide you to unforgettable experiences without the stress of negotiations.</p>
                </div>
                <div className="workingpic">
                <img src={Working3} alt="" height='300px' width='400px' />

                    
                </div>
            </div>
            {/*  */}
            <div className="work work1">
            <div className="workingpic">
            <img src={Working4} alt="" height='300px' width='400px' />

                    
                    </div>
                <div className="working_info">
                    <h3>Join our vibrant traveler community for sharing adventures and tips.</h3>
                    <p>"Experience our meticulously crafted traveler community, where you can both share your own adventures and indulge in the travel stories and tips of fellow explorers, fostering a rich exchange of experiences and insights."</p>
                </div>
                
            </div>
        </div>
      
    </div>
  )
}
