import React from 'react'
import Marquee from 'react-fast-marquee'
 import pic1 from '../Assets/Slider/1.jpg'
 import pic2 from '../Assets/Slider/2.jpg'
 import pic3 from '../Assets/Slider/3.jpg'
 import pic4 from '../Assets/Slider/4.jpg'
 import pic5 from '../Assets/Slider/5.jpg'
 import pic6 from '../Assets/Slider/6.jpg'
 import pic7 from '../Assets/Slider/7.jpg'
 import pic8 from '../Assets/Slider/8.jpg'
 import pic9 from '../Assets/Slider/9.jpg'
 import Marque from './Marquee'

export default function ImageSlider() {
  return (
    <div className="image_slider">
 <div className='image_sliderContent'>
    <Marquee direction='down' className='slider-marquee'>
        <img src={pic1} alt="" height={200} width={250} />
        <img src={pic2} alt="" height={200} width={250} />
        <img src={pic3} alt="" height={200} width={250} />

    </Marquee>
    <Marquee direction='up' className='slider-marquee'>
        <img src={pic4} alt="" height={200} width={250} />
        <img src={pic5} alt="" height={200} width={250} />
        <img src={pic6} alt="" height={200} width={250} />

    </Marquee>
    <Marquee direction='down' className='slider-marquee'>
        <img src={pic7} alt="" height={200} width={250} />
        <img src={pic8} alt="" height={200} width={250} />
        <img src={pic9} alt="" height={200} width={250} />

    </Marquee>

      
</div>
 <Marque/>
    </div>
   
  )
}
