import React from 'react'
import logo from '../Assets/logo.png'
import { LiaLinkedinIn } from "react-icons/lia";
import { LiaInstagram } from "react-icons/lia";

function Footer() {
  return (
    <div className='footer'>
        <div className="footer_contaner">
            <img src={logo} alt="" height='40px' width='70px' />
            <h3>©2024 Drifter</h3>
            <div className="social">
           <a href="https://www.linkedin.com/company/bedrifter/ "> <LiaLinkedinIn size={30} /></a>
             <a href="https://www.instagram.com/drifter.in/"><LiaInstagram size={30} /></a>
            </div>
        </div>
      
    </div>
  )
}

export default Footer
