import React from 'react'
import 'animate.css';


export default function Hero1({ scrollToContact }) {
  return (
    <div className="hero1">
<div className='hero_container'>
        <h1>Travel Like a Drifter</h1>

        <p> "Embark on unforgettable journeys and unlock the thrill of exploration with us - where every step is a new adventure waiting to be discovered."</p>

        <button onClick={scrollToContact}>Notify Me</button>
      
    </div>
    </div>
    
  )
}
