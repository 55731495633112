import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import line from '../Assets/line.png'

export default function Compare() {
    const items = [
        // { name: 'Features', col1: true, col2: false, col3: true, col4: false },
        { name: 'Hassel Free', col1: true, col2: false, col3: true, col4: true },
        { name: 'Flexible', col1: false, col2: true, col3: true, col4: false },
        { name: 'cost-effective', col1: false, col2: true, col3: true, col4: true },
        { name: 'personalized recommendations', col1: false, col2: false, col3: true, col4: false },
      
      ];
    
      return (
        <div className="compare_head">
            <h1><span>How</span>  we are different</h1>
            <img src={line} alt="" height='30px' width='250px' />
            <p> Discover the Difference: Drifter vs. Traditional Travel</p>
            <div className='compare'>
           
           <table>
             <thead>
               <tr>
                 <th>Features</th>
                 <th>Guided tour</th>
                 <th>Independent Travel</th>
                 <th>Drifter</th>
                  
               </tr>
             </thead>
             <tbody>
               {items.map((item, index) => (
                 <tr key={index}>
                 <td>{item.name}</td>
                 <td>
                 {item.col1 ? (
                   <FontAwesomeIcon icon={faCheck} className="tick" />
                 ) : (
                   <FontAwesomeIcon icon={faTimes} className="cross" />
                 )}
               </td>
               <td>
                 {item.col2 ? (
                   <FontAwesomeIcon icon={faCheck} className="tick" />
                 ) : (
                   <FontAwesomeIcon icon={faTimes} className="cross" />
                 )}
               </td>
               <td>
                 {item.col3 ? (
                   <FontAwesomeIcon icon={faCheck} className="tick" />
                 ) : (
                   <FontAwesomeIcon icon={faTimes} className="cross" />
                 )}
               </td>
               
               </tr>
               ))}
             </tbody>
           </table>
         </div>
        </div>

        
        
       
      );
}
