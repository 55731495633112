 
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';


import pic from '../Assets/Contact.png'

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_5kv9m9v', 'template_xgj264h', form.current, {
        publicKey: 'vrJS7DKgqtA7oMvpf',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          toast.success('Email sent successfully!');
           
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert('no')
        },
      );
  };




  return (
    <div id="contact" className='contact' >
      <div className="contact-contaner">
        <div className="contact_left">
          <h1> Be A Drifter</h1>
          <p> "Embark on unforgettable journeys and unlock the thrill of exploration with us - where every step is a new adventure waiting to be discovered."</p>
          <div className="contact-input">
            <form ref={form} onSubmit={sendEmail}>
              
              
                 <input className='input' type="email" name="user_email" placeholder='Your Email ' /> 
              
              
                <button className='btn' type="submit">Sent</button>
              
            </form>

          </div>
          {/* <h6 id='spam'>No spam, unsubscribe at any time</h6> */}

        </div>
        <div className="contact_right">
          <img src={pic} alt="" height={250} width={400} />

        </div>

      </div>

    </div>
  )
}
