import React from 'react'
import wave from '../Assets/wave.png'
import wave1 from '../Assets/wave1.png'
import wave2 from '../Assets/wave2.png'
import wave3  from '../Assets/wave3.png'
import wave4 from '../Assets/wave4.png'
import wave5 from '../Assets/wave5.png'

export default function Wave() {
  return (
    <div className='wave'>

    <img src={wave} alt="" width='100%' />
      <div className="wave_icons">
        <img id='firstairplain' src={wave4} alt="" height='180px' width='180px' />
        <img id='calender' src={wave2} alt="" height='160px' widtth='160px' />
        <img id='earth1' src={wave1} alt="" height='190px' widtth='190px' />
        <img id='earth2' src={wave5} alt="" height='180px' widtth='180px' />
        <img id='rocket2' src={wave3} alt="" height='180px' widtth='180px' />

      </div>
    </div>
  )
}
